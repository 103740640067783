import { store } from "@/store/store";
import { NotificationDto } from "@/components/header/Notification/notification-dto";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "NotificationModule",
  store,
  dynamic: true,
})
class NotificationModule extends VuexModule {
  public unReadNotifications: NotificationDto[] = [];

  @Action({ commit: "onGetAvisos" })
  public async getAvisos() {
    return await ssmHttpService.get(API.notificaciones, null, false);
  }

  @Action
  public async modificarAviso(aviso: NotificationDto) {
    return await ssmHttpService.put(API.notificaciones + "/" + aviso.id, aviso);
  }

  @Action
  public async NotificacionVista(aviso: NotificationDto) {
    return await ssmHttpService.put(
      API.notificaciones + "/" + aviso.id,
      aviso,
      false
    );
  }

  @Mutation
  public onGetAvisos(res: NotificationDto[]) {
    this.unReadNotifications = res;
  }
}
export default getModule(NotificationModule);
